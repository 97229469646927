import * as bootstrap from 'bootstrap';

import webGLFluidEnhanced from 'webgl-fluid-enhanced';
import Parallax from 'parallax-js';
import { init } from 'ityped'
import VanillaTilt from 'vanilla-tilt'
  
  const oneElement = document.querySelector('#one')
  
  


let scene = document.getElementById('scene');
let parallaxInstance = new Parallax(scene);

init(oneElement, {
    showCursor: false,
    typeSpeed:  120,
    backDelay:  1200,
    strings: ['Award winning', 'Finest' ] });
   
webGLFluidEnhanced.simulation(document.querySelector('canvas'), {
   
// {SIM_RESOLUTION:128,DYE_RESOLUTION:512,DENSITY_DISSIPATION:.97,VELOCITY_DISSIPATION:.98,PRESSURE_DISSIPATION:.8,PRESSURE_ITERATIONS:10,CURL:5,SPLAT_RADIUS:.6,SHADING:!0,COLORFUL:!0,BACK_COLOR:{r:0,g:0,b:0},TRANSPARENT:!1,BLOOM:!1,BLOOM_ITERATIONS:8,BLOOM_RESOLUTION:256,BLOOM_INTENSITY:.8,BLOOM_THRESHOLD:.6,BLOOM_SOFT_KNEE:.7}

    SIM_RESOLUTION: 256,
    DYE_RESOLUTION: 1440,
    CAPTURE_RESOLUTION: 512,
    DENSITY_DISSIPATION: 1.02,
    PRESSURE: 0.1,
    PRESSURE_ITERATIONS: 10,
    CURL:12,
    SPLAT_RADIUS: 0.12,
    SPLAT_FORCE: 7000,
    SUNRAYS: true, // Enables sunrays effect
  SUNRAYS_RESOLUTION: 96, // Resolution of the sunrays effect
  SUNRAYS_WEIGHT: 1, // Weight of the sunrays effect
  TRANSPARENT: false, // Makes the canvas transparent if true

  COLOR_PALETTE: ['#cc211b', '#f1c593', '#e87e54', '#f193a7', '#ec6fa9'],
  HOVER: true,
  
  VELOCITY_DISSIPATION: 0.90,
  BLOOM: false,
});


const elements = document.querySelectorAll(".js-tilt");
VanillaTilt.init(elements);

//move flame
document.querySelector('.wrapper')
  .addEventListener('mousemove', event => {
    //console.log('move');
    newEvent = new event.constructor(
      event.type, event);
    document.querySelector('canvas')
      .dispatchEvent(newEvent);


});



